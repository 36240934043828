import logo from '../Assets/AElogo.png';
import '../App.css';

import pumpkin from "../Assets/pumpkin2.gif"

function Halloween() {

    return (
        <div style={{height: "100vh", width: "100vw", backgroundColor: "black"}}>
            <img className='pumpkin' src={pumpkin}></img>
        </div >
    );
}

export default Halloween;
