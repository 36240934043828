import logo from '../Assets/AElogo.png';
import '../App.css';
import { useContext, useEffect, useState } from 'react';
import { ApiContext } from './ApiContext';
import Choice from './Choice';
import { CSSTransition } from "react-transition-group"
import LineTo from "react-lineto"

function Connect({ question, fadeIn }) {

    const { ApiCall } = useContext(ApiContext)
    const [choices, set_choices] = useState([])
    const [choice, set_choice] = useState([])
    const [sending, set_sending] = useState(false)
    const [temp_choice, set_temp_choice] = useState([])

    useEffect(() => {
        if (question != null) {
            console.log("test")
            ApiCall("GET", "choice/" + question.id, null)
                .then(res => {
                    set_choices(res)
                    setTimeout(() => {
                        fadeIn(false)
                    }, 1500)
                })
        }
    }, [question])

    function postAnswer() {

        console.log("kek")

        var temp = [...choice]

        for (var i = 0; i < temp.length; i++) {
            temp[i].sort(function (a, b) {
                return a.id - b.id;
            });
        }

        console.log(temp)

        temp.sort(function (a, b) {
            return a[0].id - b[0].id;
        });

        var answer_string = ""
        for (var i = 0; i < temp.length; i++) {
            if (i != 0) {
                answer_string += ","
            }
            answer_string += temp[i][0].id + ":" + temp[i][1].id
        }

        console.log(answer_string)

        set_sending(true)

        ApiCall("POST", "answer/" + question.id, { answer: answer_string })
            .then(res => {
                console.log(res)
                fadeIn(true)
            }).catch(err => {
                set_sending(false)
                fadeIn(true)
            })
    }

    function updateChoice(c) {
        console.log(c)

        if (isUsed(c) || (temp_choice.length == 1 && temp_choice[0].id == c.id)) {
            return
        }

        if (temp_choice.length == 0) {
            let t = [...temp_choice]
            t.push(c)
            set_temp_choice(t)
        }

        else {
            temp_choice.push(c)
            let temp = [...choice]
            let t = [...temp_choice]
            temp.push(t)
            set_choice(temp)
            set_temp_choice([])
        }
    }

    const isUsed = (c) => {
        for (var i = 0; i < choice.length; i++) {
            if (choice[i][0].id == c.id || choice[i][1].id == c.id) {
                return true
            }
        }
        return false
    }

    function onLeft(c) {
        let index = choices.findIndex(obj => obj.id == c.id)
        if (index % 2 == 0) {
            return true
        }
        return false
    }

    function reset() {
        set_choice([])
        set_temp_choice([])
    }


    return (
        <>
            <div className='body-2' style={{ alignSelf: "flex-start", marginBottom: "10px", marginTop: "40px" }}>Yhdistä vaihtoehdot</div>
            <div style={{ width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between", position: "relative", alignItems: "center" }}>
                {choices.map((c, i) => {
                    return <Choice className={c.choice} pairs={choice} disabled={(temp_choice.length == 1 && (onLeft(temp_choice[0]) == onLeft(c) && temp_choice[0].id != c.id)) || isUsed(c)} highlight={temp_choice.length == 1 && temp_choice[0].id == c.id} size={"45%"} set_choice={updateChoice} isSelected={isUsed(c)} choice={c} index={i} />
                })}
                {!sending && choice.map(pair => {
                    return <div style={{ position: "absolute" }}><LineTo delay={1000} borderWidth={2} borderColor={"rgb(158,130,86)"} fromAnchor='center right' toAnchor='center left' from={onLeft(pair[0]) ? pair[0].id : pair[1].id} to={onLeft(pair[1]) ? pair[0].id : pair[1].id} /></div>
                })}
            </div>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                <div onClick={() => reset()} className='cancel-button' style={{ width: "45%" }}>Nollaa</div>
                <CSSTransition timeout={200} in={choice.length == choices.length / 2} classNames={"partial-fade-transition"} >
                    <div onClick={() => choice.length == choices.length / 2 && postAnswer()} className='login-button' style={{ width: "45%" }}>{!sending ? "Tallenna" : <div className='loader' />}</div>
                </CSSTransition>
            </div>
        </>
    );
}

export default Connect;
