import logo from '../Assets/AElogo.png';
import '../App.css';
import { useContext, useEffect, useState } from 'react';
import { ApiContext } from './ApiContext';
import Choice from './Choice';
import { CSSTransition } from "react-transition-group"

function MultiSelection({ question, fadeIn }) {

    const { ApiCall } = useContext(ApiContext)
    const [code, set_code] = useState("")
    const [choices, set_choices] = useState([])
    const [choice, set_choice] = useState([])

    useEffect(() => {
        if (question != null) {
            console.log("test")
            ApiCall("GET", "choice/" + question.id, null)
                .then(res => {
                    set_choices(res)
                    setTimeout(() => {
                        fadeIn(false)
                    }, 1500)
                })
        }
    }, [question])

    function postAnswer() {

        let t_arr = [...choice]

        t_arr.sort((a, b) => a.id - b.id);

        let temp = ""
        for (var i = 0; i < t_arr.length; i++) {
            if (i != 0) {
                temp += ","
            }
            temp += t_arr[i].id
        }


        ApiCall("POST", "answer/" + question.id, { answer: temp })
            .then(res => {
                console.log(res)
                fadeIn(true)
            }).catch(err => {
                fadeIn(true)
            })
    }

    function updateChoice(c) {
        let temp = [...choice]
        let index = temp.findIndex(obj => obj.id == c.id)
        if (index == -1) {
            console.log("not found found")
            temp.push(c)
        }
        else {
            temp.splice(index, 1)
        }
        set_choice(temp)
    }

    function hasItem(c) {
        let index = choice.findIndex(obj => obj.id == c.id)
        if (index == - 1) {
            return false
        }
        return true
    }

    return (
        <>
            <div className='body-2' style={{ alignSelf: "flex-start", marginBottom: "10px", marginTop: "10px" }}>Monivalinta</div>
            <div className='question-container'>
                {choices.map((c, i) => {
                    return <>
                        <Choice set_choice={updateChoice} isSelected={hasItem(c)} choice={c} index={i} />
                        {i != choices.length - 1 && <div className='split-line'></div>}
                    </>
                })}
            </div>
            <CSSTransition timeout={200} in={choice.length > 0} classNames={"fade-transition"} unmountOnExit>
                <div onClick={() => postAnswer()} className='login-button'>Tallenna vastaus</div>
            </CSSTransition>
        </>
    );
}

export default MultiSelection;
