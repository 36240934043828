import logo from '../Assets/AElogo.png';
import '../App.css';
import { lazy, useContext, useEffect, useRef, useState } from 'react';
import { ApiContext } from './ApiContext';
import { CSSTransition } from 'react-transition-group';
import { useIsVisible } from '../Helpers/isVisible';

export function GuestBookDisplay({ name, wishes, image, loading = false }) {

    return <div className='guest-book-image-container'>
        <div className='header-1' style={{ alignSelf: "left" }}>{name}</div>
        <div className='header-2' style={{ alignSelf: "left", fontStyle: "italic", marginTop: "10px", wordWrap: "break-word", whiteSpace: "normal" }}>{wishes}</div>
        {loading && <div style={{ alignSelf: "center", marginTop: "20px", marginBottom: "20px" }} className='loader' />}
        <CSSTransition timeout={200} in={image != null} classNames={"fade-transition"} unmountOnExit >
            {image != null && <img src={image} className="guest-book-image" />}
        </CSSTransition>
    </div>
}

export default function GuestbookEntry({ entry }) {

    const ref = useRef()

    const isVisible = useIsVisible(ref);

    useEffect(() => {
        console.log(isVisible)
    }, [isVisible])

    const { ApiCall } = useContext(ApiContext)

    const [image, set_image] = useState(null)
    const [loading, set_loading] = useState(true)

    useEffect(() => {
        if (isVisible) {
            ApiCall("GET", "score/get_guest_book_image/" + entry.id, null)
                .then(res => {
                    set_image(res.image)
                    set_loading(false)
                }).catch(err => {
                    set_loading(false)
                })
        }
    }, [isVisible])


    return <div style={{width: "100%"}} ref={ref}><GuestBookDisplay name={entry.user.name} wishes={entry.additional_answer} image={image} loading={loading} /></div>
}