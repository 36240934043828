import logo from '../Assets/AElogo.png';
import '../App.css';
import { useContext, useEffect, useMemo, useState } from 'react';
import { ApiContext } from './ApiContext';
import Selection from './Selection';
import MultiSelection from './MultiSelection';
import OrderSelection from './OrderSelection';
import { CSSTransition } from 'react-transition-group';
import Connect from './Connect';
import { useNavigate } from 'react-router-dom';

import valssi from "../Assets/EAValssi.mp3"

function ThankYouPage({ fadeIn }) {

    const song = useMemo(() => new Audio(valssi), [valssi]);

    const navigate = useNavigate();

    const [user, setUser] = useState({})

    const { ApiCall } = useContext(ApiContext)
    const [loading, set_loading] = useState(false)
    const [playing, set_playing] = useState(false)

    const [song_length, set_song_length] = useState(0)
    const [current_timestamp, set_current_timestamp] = useState(0)

    const startSong = () => {
        console.log("Playing")
        set_playing(true)
        song.play()
    }

    const stopSong = () => {
        console.log("Pausing")
        set_playing(false)
        song.pause()
    }

    function secondsToTimestamp(seconds) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.floor(seconds % 60);


        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
        const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;


        return `${formattedMinutes}:${formattedSeconds}`;
    }


    useEffect(() => {
        ApiCall("GET", "user/get_user_scores", null)
            .then(res => {
                setUser(res)
                fadeIn(false)
            }).catch(err => {
                console.log(err)
            })
    }, [])

    useEffect(() => {
        song.addEventListener('timeupdate', (e) => set_current_timestamp(e.target.currentTime));
        song.addEventListener("loadedmetadata", (e) => set_song_length(e.target.duration))
        song.addEventListener("ended", () => {
            set_playing(false)
            set_current_timestamp(0)
        })
    }, []);

    return (
        <div className='container' style={{ minHeight: "100vh" }}>
            <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", padding: 0, boxSizing: "border-box" }}>
                <div className="seal" style={{ position: "relative", minHeight: "80px", minWidth: "80px", height: "80px", width: "80px", margin: 0, left: 0, top: 0, right: 0, bottom: 0 }}>
                    <span className="embossed">
                        <img className='stamp' src={logo}></img>
                    </span>
                </div>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                    <div className='header-2' style={{ fontSize: "18px" }}>Kiitos osallistumisesta,</div>
                    <div className='header-1' style={{ marginLeft: "20px" }}>{user.name + "!"}</div>
                </div>
            </div>
            <div className='clues-container' style={{ overflow: "visible", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                <div style={{ position: "relative", width: "80px", height: "80px" }}>
                    <div className='ribbon' />
                    <div className="newseal" style={{ position: "absolute", minHeight: "80px", minWidth: "80px", height: "80px", width: "80px", margin: 0, left: 0, top: 0, right: 0, bottom: 0 }}>
                        <span className="newembossed">
                            <div className='header-1' style={{ color: "white", position: "absolute" }}>{user.position + "."}</div>
                        </span>
                    </div>
                </div>
                <div className='header-2'>{user.total_points + "/" + user.max_points}</div>
            </div>
            <div onClick={() => navigate("/guestbookwinners")} className='big-button'>Katso kuvakilpailun voittajat täältä</div>
            <div onClick={() => navigate("/guestbook")} className='login-button'>Vieraskirja</div>
            <div onClick={() => navigate("/answers")} className='login-button'>Oikeat vastaukset</div>

            <div onClick={playing ? stopSong : startSong} className={"cancel-button"} style={{ position: "relative", overflow: "hidden" }}>
                <div style={{ height: "100%", width: `${current_timestamp / song_length * 100}%`, position: "absolute", left: 0, top: 0, backgroundColor: "#DDAD66" }}>
                </div>
                <div style={{ position: "absolute" }}>{!playing ? "Kuuntele häävalssi tästä" : secondsToTimestamp(current_timestamp) + "/" + secondsToTimestamp(song_length)}</div>
            </div>
        </div>
    );
}

export default ThankYouPage;
