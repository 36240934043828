import '../App.css';
import { useContext, useEffect, useState } from 'react';
import { ApiContext } from './ApiContext';
import { CSSTransition } from "react-transition-group"
import Resizer from "react-image-file-resizer";
import { GuestBookDisplay } from './GuestBookEntry';


function ImageUpload({ question, fadeIn, user, setHideQuestion}) {
    console.log(user)
    const { ApiCall } = useContext(ApiContext)
    const [sending, set_sending] = useState(false)
    const [image, set_image] = useState(null)
    const [resized_base64_image, set_resized_base64_image] = useState(null)
    const [wishes, set_wishes] = useState("");
    const [confirming, set_confirming] = useState(false);

    useEffect(() => {
        console.log("Resizing image");
        resizeFile(image)
            .then(image64 => {
                set_resized_base64_image(image64)
            }).catch(err => {
                console.log(err)
                set_resized_base64_image(null);
                return
            })
    }, [image])

    useEffect(() => {
        if (question != null) {
            setTimeout(() => {
                fadeIn(false)
            }, 1500)

        }
    }, [question])

    const resizeFile = (file) => new Promise(resolve => {
        Resizer.imageFileResizer(file, 1500, 1500, 'JPEG', 100, 0,
            uri => {
                resolve(uri);
            }, 'base64');
    });

    function postAnswer() {
        console.log("kek")
        set_sending(true)
        ApiCall("POST", "answer/" + question.id, { answer: resized_base64_image, additional_answer: wishes })
            .then(res => {
                set_sending(false)
                console.log(res)
                fadeIn(true)
            }).catch(err => {
                set_sending(false)
                fadeIn(true)
            })
    }

    useEffect(() => {
        setHideQuestion(confirming)
    }, [confirming])
    
    if(confirming) {
        return <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start", textAlign: "start", wordWrap: "break-word", whiteSpace: "normal" }}>
            <div className='header-1'>Oletko tyytyväinen kuvaan ja terveisiin?</div>
            <div className='header-2' style={{marginTop: "10px", marginBottom: "40px"}}>Kuva ja terveiset ovat julkisia, etkä voi muokata niitä lähetyksen jälkeen</div>
            
            <GuestBookDisplay name={user.name} wishes={wishes} image={resized_base64_image} />
            <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
            <div className='cancel-button' onClick={() => set_confirming(false)} style={{width: "48%"}}>Peruuta</div>
            <div className='login-button' onClick={() => postAnswer()} style={{width:"48%"}}>{!sending ? "Lähetä" : <div className='loader' />}</div>
            </div>

        </div>
    }
    else {
        return (
            <>
                <div style={{ height: "40vh", width: "100%", display: "flex", alignItems: "center", justifyContent: "center", marginTop: "20px" }}>
                    {image && (
                        <img
                            alt="not found"
                            style={{ height: "40vh", width: "100%", objectPosition: "center", objectFit: "cover", borderRadius: "4px" }}
                            src={URL.createObjectURL(image)}
                        />
                    )}
                    {image == null && (
                        <div style={{ height: "40vh", width: "100%", borderRadius: "4px", border: "dashed", borderWidth: "1px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <label for="upload-photo">Valitse kuva napauttamalla </label>
                            <input type="file"
                                accept='image/*'
                                style={{ backgroundColor: "transparent", border: "0px solid" }}
                                onChange={(event) => {
                                    console.log(event.target.files[0]);
                                    set_image(event.target.files[0]);
                                }}
                                id="upload-photo"></input>
                        </div>
                    )}
                </div>
                <div className='cancel-button' style={{ marginTop: "10px", opacity: image ? "1" : "0" }} onClick={() => set_image(null)}>Poista kuva</div>


                <div style={{ width: "100%", margin: "10px", display: "flex" }}>
                    <textarea rows="3" placeholder='Kirjoita terveiset'
                        style={{ width: "100%", borderRadius: "5px", padding: "15px", fontSize: "16px", fontFamily: "PT Sans" }}
                        value={wishes}
                        onChange={(e) => {
                            set_wishes(e.target.value)
                        }}
                    />
                </div>
                <CSSTransition timeout={200} in={image != null || wishes != ""} classNames={"fade-transition"} unmountOnExit >
                    <div onClick={() => set_confirming(true)} className='login-button'>Lähetä</div>
                </CSSTransition>
            </>
        );
    }
}

export default ImageUpload;
