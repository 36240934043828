/* eslint-disable indent */
import React, { createContext } from "react";

import axios from "axios";
import { BASE_URL } from "../env";
/* import { useNavigate } from "react-router-dom"; */

export const ApiContext = createContext();

export const ApiProvider = ({ children }) => {
  /*  const navigate = useNavigate(); */

  const ApiCall = (method, url, body) => {
    // console.log(method, url, body);

    return new Promise((resolve, reject) => {
        console.log(url)
      let token = document.cookie.split("token-ea=")[1];
      // console.log(token);
      if (token !== undefined) token = token.split(";")[0];

      axios({
        method,
        url: BASE_URL + url,

        data: body,
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => {
          resolve(res.data);
          // console.log(res);
        })
        .catch((err) => {
          console.log(err)
          console.log("Perkele")
          // console.log(err.response.data.error);
          document.cookie = "";

          // TODO: erase cookies and route to login if status code is 401
          // navigate("/login");
          /* if (err.response.status === 401 && navigate !== null) navigate("/"); */

          reject(err);
        });
    });
  };

  return (
    <ApiContext.Provider
      value={{
        ApiCall,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};
