import logo from '../Assets/AElogo.png';
import '../App.css';
import { useContext, useEffect, useState } from 'react';
import { ApiContext } from './ApiContext';
import Choice from './Choice';
import { CSSTransition } from "react-transition-group"

function Selection({ question, fadeIn }) {

    const { ApiCall } = useContext(ApiContext)
    const [choices, set_choices] = useState([])
    const [choice, set_choice] = useState(null)
    const [sending, set_sending] = useState(false)

    useEffect(() => {
        if (question != null) {
            console.log("test")
            ApiCall("GET", "choice/" + question.id, null)
                .then(res => {
                    set_choices(res)
                    setTimeout(() => {
                        fadeIn(false)
                    }, 1500)
                })
        }
    }, [question])

    function postAnswer() {
        set_sending(true)

        ApiCall("POST", "answer/" + question.id, { answer: choice.id })
            .then(res => {
                set_sending(false)
                console.log(res)
                fadeIn(true)
            }).catch(err => {
                set_sending(false)
                fadeIn(true)
            })
    }

    function updateChoice(c) {
        console.log(c)
        if (choice == null) {
            set_choice(c)
        }
        else if (choice.id == c.id) {
            set_choice(null)
        }
        else {
            set_choice(c)
        }
    }

    return (
        <>
            <div className='body-2' style={{ alignSelf: "flex-start", marginBottom: "10px", marginTop: "10px" }}>Valitse oikea vastaus</div>
            <div className='question-container'>
                {choices.map((c, i) => {
                    return <>
                        <Choice set_choice={updateChoice} isSelected={choice != null && choice.id == c.id} choice={c} index={i} />
                        {i != choices.length - 1 && <div className='split-line'></div>}
                    </>
                })}
            </div>
            <CSSTransition timeout={200} in={choice != null} classNames={"fade-transition"} unmountOnExit >
                <div onClick={() => postAnswer()} className='login-button'>{!sending ? "Tallenna vastaus" : <div className='loader' />}</div>
            </CSSTransition>
        </>
    );
}

export default Selection;
