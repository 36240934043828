import logo from '../Assets/AElogo.png';
import '../App.css';
import { useContext, useEffect, useState } from 'react';
import { ApiContext } from './ApiContext';
import Selection from './Selection';
import MultiSelection from './MultiSelection';
import OrderSelection from './OrderSelection';
import { CSSTransition } from 'react-transition-group';
import Connect from './Connect';
import ImageUpload from './ImageUpload';
import { useNavigate } from 'react-router-dom';

function QuestionContainer({ fadeIn, user }) {

    const navigate = useNavigate();

    const { ApiCall } = useContext(ApiContext)
    const [code, set_code] = useState("")
    const [question, set_question] = useState(null)
    const [clues, set_clues] = useState([])
    const [fetching_clue, set_fetching_clue] = useState(false)
    const [no_more_clues, set_no_more_clues] = useState(false)
    const [show_clue_button, set_show_clue_button] = useState(true)
    const [available_points, set_available_points] = useState(0)

    const [hideQuestion, setHideQuestion] = useState(false)

    function getNewQuestion(timeout) {
        ApiCall("GET", "question", null)
            .then(res => {
                set_question(res)
                if (res.id == null) {
                    setTimeout(() => {
                        fadeIn(false)
                    }, 1000)
                }
            }).catch(err => {
                console.log(err)
            })
    }

    function getClues() {
        set_fetching_clue(true)
        ApiCall("GET", "clue/" + question.id, null)
            .then(res => {
                set_clues(res)
                console.log(res)
                set_fetching_clue(false)
            }).catch(err => {
                console.log(err)
            })
    }

    function getNewClue() {
        set_fetching_clue(true)
        ApiCall("GET", "clue/next/" + question.id, null)
            .then(res => {
                getClues()
            }).catch(err => {
                console.log(err)
                set_fetching_clue(false)
                set_no_more_clues(true)
            })
    }

    useEffect(() => {
        getNewQuestion()
    }, [])

    useEffect(() => {
        if (question != null && question.id != null) {
            getClues()
        }
    }, [question])

    useEffect(() => {
        if (question == null) {
            return
        }
        if (clues.length == 0) {
            set_available_points(question.base_points)
        }
        else {
            set_available_points(clues[clues.length - 1].points)
        }
    }, [clues])

    return (
        <div className='container'>
            {question != null && question.id != null && !hideQuestion && <div style={{ display: "flex", flexDirection: "row", marginBottom: "10px", justifyContent: "space-between", alignItems: "flex-start", width: "100%" }}>
                <div className='header-1' style={{ whiteSpace: "normal", textAlign: "left" }}>{question.question}</div>
            </div>
            }
            {question != null && question.image != null && <img src={question.image} style={{ width: "100%", marginTop: "20px", maxHeight: "200px", objectFit: "cover", borderRadius: "4px", overflow: "hidden" }} />}
            {user.answers != user.total_questions && question != null && question.type != 5 &&
                <div className='clues-container'>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", height: "40px", width: "100%", boxSizing: "border-box" }}>
                        <div className='points-container'>
                            <div className='body-2'>{available_points + " pistettä"}</div>
                        </div>
                        {question != null && question.id != null &&
                            <CSSTransition timeout={200} in={show_clue_button} classNames={"fade-transition"} unmountOnExit>
                                <div onAnimationEnd={() => set_show_clue_button(false)} onClick={() => getNewClue()} className={no_more_clues ? "clue-button shake" : "clue-button"}>{fetching_clue ? <div className='loader' /> : "Uusi vihje"}</div>
                            </CSSTransition>
                        }
                    </div>
                    {clues.map((clue, i) => {
                        return <div style={{ flexDirection: "row", display: "flex", alignItems: "flex-start", width: "100%", marginTop: i == 0 ? 20 : 10 }}>
                            <div className='body-2' style={{ whiteSpace: "normal", textAlign: "left" }}>{(i + 1) + ". "}</div>
                            <div style={{width: "10px"}}/>
                            <div className='body-2' style={{ whiteSpace: "normal", textAlign: "left", color: "white" }} dangerouslySetInnerHTML={{__html: clue.clue}}></div>
                        </div>
                    })}
                </div>
            }
            {question != null && question.type == 1 &&
                <Selection fadeIn={fadeIn} question={question}></Selection>
            }
            {question != null && question.type == 2 &&
                <MultiSelection fadeIn={fadeIn} question={question} />
            }
            {question != null && question.type == 3 &&
                <OrderSelection fadeIn={fadeIn} question={question} />
            }
            {question != null && question.type == 4 &&
                <Connect fadeIn={fadeIn} question={question} />
            }
            {question != null && question.type == 5 &&
                <ImageUpload setHideQuestion={setHideQuestion} fadeIn={fadeIn} question={question} user={user} />
            }
            {question != null && question.id == null &&
                <div className='header-2' style={{ whiteSpace: "normal" }}>Kiitos osallistumisesta!</div>}
            {question != null && question.id == null &&
                <div onClick={() => navigate("/guestbook")} className='login-button' style={{ whiteSpace: "normal" }}>Siirry katsomaan vieraskirjaa</div>}
        </div>
    );
}

export default QuestionContainer;
