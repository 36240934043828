import logo from '../Assets/AElogo.png';
import '../App.css';
import { lazy, useContext, useEffect, useState } from 'react';
import { ApiContext } from './ApiContext';
import { CSSTransition } from 'react-transition-group';
import GuestbookEntry from './GuestBookEntry';

function ImageList({ }) {

    const { ApiCall } = useContext(ApiContext)
    const [loading, set_loading] = useState(false)
    const [images, set_images] = useState([])


    useEffect(() => {
        set_loading(true)
        setTimeout(() => {
            ApiCall("GET", "score/get_guest_book_entries", null)
                .then(res => {
                    console.log(res)
                    set_images(res.entries)
                    set_loading(false)
                }).catch(err => {
                    set_loading(false)
                })
        }, 1500)
    }, [])

    return (<>
        <div className='App'>
            {!loading && <>
            <div className='header'>
                <div style={{ flex: 1, display: "flex" }}>
                    <div className="seal" style={{ position: "relative", height: "50px", width: "50px", margin: 0, left: 0, top: 0, right: 0, bottom: 0 }}>
                        <span className="embossed">
                            <img className='stamp' src={logo}></img>
                        </span>
                    </div>
                </div>
                <div className='header-1' style={{ fontSize: 30, alignSelf: "flex-start", marginTop: 30, marginBottom: 30, marginLeft: 30 }}>Vieraskirja</div>
            </div>
            <div className='container'>
                {images.map((image, i) => {
                    return <GuestbookEntry entry={image} />
                })}
            </div>
            </>}
        </div>
        <CSSTransition in={loading} timeout={1000} classNames="slide-from-bottom" unmountOnExit>
            <div className="door" style={{ top: "50%" }}>
            </div>
        </CSSTransition>
        <CSSTransition in={loading} timeout={1000} classNames="slide-from-top" unmountOnExit>
            <div className="door" style={{ borderBottom: "3px solid rgba(0,0,0)" }}>
            </div>
        </CSSTransition>

        <CSSTransition in={loading} timeout={2000} classNames="badge-animation" unmountOnExit>

            <div className="seal">
                <span className="embossed">
                    <img className='stamp' src={logo}></img>
                </span>
            </div>
        </CSSTransition>
        <CSSTransition in={loading} timeout={2000} classNames="drop-1" unmountOnExit>
            <div className='drop-1'></div>
        </CSSTransition>
        <CSSTransition in={loading} timeout={2000} classNames="drop-2" unmountOnExit>
            <div className='drop-2'></div>
        </CSSTransition>
    </>
    );
}

export default ImageList;
