import logo from '../Assets/AElogo.png';
import '../App.css';
import { useContext, useEffect, useState } from 'react';
import { ApiContext } from './ApiContext';
import Selection from './Selection';
import MultiSelection from './MultiSelection';
import OrderSelection from './OrderSelection';
import { CSSTransition } from 'react-transition-group';
import Connect from './Connect';

function Terms({ fadeIn, user }) {

    const { ApiCall } = useContext(ApiContext)
    const [loading, set_loading] = useState(false)
    const [terms_accepted, set_terms_accepted] = useState(false)
    const [policy_accepted, set_policy_accepted] = useState(false)

    useEffect(() => {
        console.log("kek")
        setTimeout(() => {
            fadeIn(false)
        }, 1500)
    }, [])

    function acceptTerms() {
        ApiCall("POST", "user/accept_terms", null)
            .then(res => {
                console.log(res)
                set_loading(false)
                fadeIn(true)
            }).catch(err => {
                console.log(err)
                set_loading(false)
            })
    }

    return (
        <div className='container' style={{ minHeight: "100vh" }}>
            <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", padding: 0, boxSizing: "border-box" }}>
                <div className="seal" style={{ position: "relative", minHeight: "80px", minWidth: "80px", height: "80px", width: "80px", margin: 0, left: 0, top: 0, right: 0, bottom: 0 }}>
                    <span className="embossed">
                        <img className='stamp' src={logo}></img>
                    </span>
                </div>
                <div style={{display: "flex", flexDirection: "column", alignItems: "flex-end"}}>
                    <div className='header-2' style={{fontSize: "18px"}}>Tervetuloa hääpeliin,</div>
                    <div className='header-1' style={{ marginLeft: "20px" }}>{user.name + "!"}</div>
                </div>
            </div>
            <div style={{height: "40px"}}></div>
            <div className='tutorial-list-item'>
                <div className='list-square'></div>
                <div className='body-2' style={{ whiteSpace: "normal", textAlign: "left" }}>Kerää pisteitä vastaamalla oikein eri tyyppisiin kysymyksiin</div>
            </div>
            <div className='tutorial-list-item'>
                <div className='list-square'></div>
                <div className='body-2' style={{ whiteSpace: "normal", textAlign: "left" }}>Voit käyttää vihjeitä kysymyksiin mutta kysymyksestä saatava pistemäärä vähenee</div>
            </div>
            <div className='tutorial-list-item' style={{ marginBottom: 30 }}>
                <div className='list-square'></div>
                <div className='body-2' style={{ whiteSpace: "normal", textAlign: "left" }}>Pelin voittaa eniten pisteitä kerännyt kilpailija. Voit tarkistaa tämänhetkisen pistetilanteen Magic Mirrorilta</div>
            </div>
            <div className='clues-container'>
                <div className='body-2' style={{ whiteSpace: "normal", textAlign: "left" }}>Ennen kuin jatkat pelaamaan, olethan lukenut</div>
                <div className='tutorial-list-item' style={{ alignItems: "center", paddingLeft: 0 }}>
                    <div onClick={() => set_terms_accepted(!terms_accepted)} className='custom-checkbox'>
                        {terms_accepted && <div className='inner-checkbox'></div>}
                    </div>
                    <a href="/kayttoehdotus.pdf" target='_blank' className='body-2' style={{ textDecoration: "underline", color: "white" }}>Käyttöehdotuksen</a>
                </div>
                <div className='tutorial-list-item' style={{ alignItems: "center", paddingLeft: 0 }}>
                    <div onClick={() => set_policy_accepted(!policy_accepted)} className='custom-checkbox'>
                        {policy_accepted && <div className='inner-checkbox'></div>}
                    </div>
                    <a href="/tietosuojasepite.pdf" target='_blank' className='body-2' style={{ textDecoration: "underline", color: "white" }}>Tietosuojasepitteen</a>
                </div>
                <CSSTransition timeout={200} in={terms_accepted && policy_accepted} classNames={"fade-transition"} unmountOnExit>
                    <div onClick={() => acceptTerms()} className='login-button' style={{ width: "100%" }}>Jatka pelaamaan</div>
                </CSSTransition>
            </div>
        </div>
    );
}

export default Terms;
