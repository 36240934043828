import logo from '../Assets/AElogo.png';
import '../App.css';
import { useContext, useState } from 'react';
import { ApiContext } from './ApiContext';
import { CSSTransition } from 'react-transition-group';

function Login({ fadeIn }) {

    const { ApiCall } = useContext(ApiContext)
    const [code, set_code] = useState("")
    const [failed_login, set_failed_login] = useState(false)
    const [loading, set_loading] = useState(false)

    function setCode(e) {
        set_code(e.target.value)
    }

    function login() {
        set_loading(true)
        ApiCall("POST", "user/login", { user_id: code.toUpperCase() })
            .then(res => {
                set_loading(false)
                console.log(res)
                document.cookie = "token-ea=" + res.token;
                fadeIn(true)
            }).catch(err => {
                set_loading(false)
                set_failed_login(true)
            })
    }

    return (
        <div className='container'>
           <div className="seal" style={{ position: "relative", height: "100px", width: "100px", margin: 0, left: 0, top: 0, right: 0, bottom: 0, marginTop: 50, marginBottom: 50 }}>
                    <span className="embossed">
                        <img className='stamp' src={logo}></img>
                    </span>
                </div>
            <div style={{ marginBottom: "20px" }}>
                <div className='header-1' style={{alignSelf: "flex-start", textAlign: "flex-start"}}>Syötä pelaajakoodisi</div>
            </div>
            <div className='login-text-container'>
                <div className='login-text-container-square'>
                    <div className='header-1'>#</div>
                </div>
                <input className='text-input' type={"text"} onChange={e => setCode(e)}></input>
            </div>
            <CSSTransition timeout={200} in={code.length > 0} classNames={"fade-transition"} unmountOnExit>
                <button onAnimationEnd={() => set_failed_login(false)} onClick={() => login()} className={failed_login ? "login-button shake" : "login-button"}>{!loading ? "Jatka" : <div className='loader'></div>}</button>
            </CSSTransition>
        </div>
    );
}

export default Login;
