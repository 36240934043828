import logo from '../Assets/AElogo.png';
import '../App.css';
import { useContext, useEffect, useState } from 'react';
import { ApiContext } from './ApiContext';

function Header({ user, fadeIn }) {

    const [time_left, set_time_left] = useState("")

    function getTimeDifference(start, end) {
        var startTime = new Date(start);
        var endTime = new Date(end);
      
        // Calculate the time difference in milliseconds
        var timeDiff = endTime - startTime;

        if (timeDiff < 0) {
            // fadeIn(true)
            return "Peliaika loppunut"
        }
      
        // Calculate the number of days, hours, minutes, and seconds
        var days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
        var hours = Math.floor((timeDiff / (1000 * 60 * 60)) % 24);
        var minutes = Math.floor((timeDiff / (1000 * 60)) % 60);
        var seconds = Math.floor((timeDiff / 1000) % 60);
      
        // Determine the largest unit of time
        if (days > 0) {
          return days + (days === 1 ? " päivä" : " päivää");
        } else if (hours > 0) {
          return hours + " h";
        } else if (minutes > 0) {
          return minutes + " min";
        } else {
          return seconds + " s";
        }
      }

    useEffect(() => {
        setInterval(() => {
            const now = new Date()
            const end = new Date(process.env.REACT_APP_END_TIME * 1000)
            const diff = getTimeDifference(now, end)
            set_time_left(diff)
        }, 1000)
    }, [])

    return (
        <div className='header'>
            <div style={{ flex: 1, display: "flex" }}>
            <div className="seal" style={{ position: "relative", height: "50px", width: "50px", margin: 0, left: 0, top: 0, right: 0, bottom: 0 }}>
                    <span className="embossed">
                        <img className='stamp' src={logo}></img>
                    </span>
                </div>
            </div>
            {user != null &&
                <>
                    {
                        user.answers != user.total_questions ?
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <div className='header-1'>
                                {(user.answers + 1) + "/" + user.total_questions}
                            </div>
                            <div className='header-3' style={{fontSize: 14, color: "rgba(255,255,255,0.6)"}}>{time_left}</div>
                        </div>
                        : <div style={{ display: "flex", flexDirection: "column" }}>
                        <div className='header-3' style={{fontSize: 14, color: "rgba(255,255,255,0.6)"}}>{time_left}</div>
                    </div>
                    }

                    {user != null && <div className='header-2' style={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>{user.name}</div>}
                </>
            }
        </div >
    );
}

export default Header;
