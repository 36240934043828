import '../App.css';
import { useContext, useEffect, useState } from 'react';
import { ApiContext } from './ApiContext';

function ScoreBoardWithGuestBookImages() {

    
    const { ApiCall } = useContext(ApiContext)
    const [image, set_image] = useState(null)
    const [landscape, set_landscape] = useState(null);

    const [scores, set_scores] = useState([])

    useEffect(() => {
        setInterval(() => {
            console.log("Fetching new image")
            setTimeout(() => {
                ApiCall("GET", "score/get_random_image", null)
                    .then(res => {
                        console.log(res)
                        if(res && res.answer) {
                            const decodedImage = new Image();
                            decodedImage.src = res.answer;
                            decodedImage.onload = function(e) {
                                console.log(e);
                                console.log(`Decoded image with width ${decodedImage.width} and height ${decodedImage.height}`)
                                set_landscape(decodedImage ? decodedImage.width > decodedImage.height : false)
                                set_image(res)
                            }
                        }
                    }).catch(err => {
                        console.error(err)
                    })
            }, 600)
            ApiCall("GET", "score/top_list/10", null)
            .then(res => {
                console.log(res)
                set_scores(res)
            }).catch(err => {
                console.log(err)
            })
        }, 15000)
    }, [])
    return (
        <div className='container' style={{ minHeight: "100vh", maxHeight: "100vh", minWidth: "100vw", maxWidth: "100vw", justifyContent: "space-between", alignItems: "center", flexDirection: "row" }}>
            <div style={{width: "20vw", height: "80vh"}}>
                <div className='header-1' style={{fontSize: 60}}>Pistetilanne</div>
                {scores.map((user, index) => {
                    return <div style={{flexDirection: "row", display: "flex"}}>
                        <div className='header-1'>{(index + 1) + ". "}</div>
                        <div style={{width: "10px"}}/>
                        <div className='header-1'>{user.name}</div>
                        <div style={{width: "10px"}}/>
                        <div className='header-1'>{user.total_points}</div>
                    </div>
                })}
            </div>
                <div style={{overflow: "hidden",  borderRadius: "4px", backgroundColor: "#2C3137", padding: "10px", maxHeight: "90vh", maxWidth: "70vw"}}>
                    {image && image.answer &&
                    <div style={{ display: "flex", flexDirection: landscape ? "column": "row"}}>
                        <div style={{ display: "flex", justifyContent: "center", alignContent: "center"}}>
                            <img src={image.answer} className='magic-mirror-image' style={{maxHeight: landscape ? "65vh" : "84vh", maxWidth: landscape ? "70vw" : "40vw"}}/>
                        </div>
                        <div style={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "flex-start"}}>
                            <div className='header-1' style={{ fontSize: 50, margin: "25px"}}>{image.user && image.user.name}</div>
                            <div className='header-1' style={{ fontSize: 25, fontStyle: "italic", margin: "25px", whiteSpace: "normal" }}>{image.additional_answer}</div>
                        </div>
                    </div>}
                </div>
            </div>
    );
}
function PlacementMap() {
    return (
        <div className='container' style={{ height: "100vh", width: "100vw",}}>
            <img src='placement_map.png' alt="placement map" style={{ height: "100%", width:"100%", objectFit:"contain"}}></img>
        </div>
    );
}

function GuestBookMM() {
    //const start = new Date(1691130861 * 1000)
    const start = new Date(process.env.REACT_APP_START_TIME * 1000)
    const now = new Date();
    const delta = start - now;

    console.log(`${start},\n${now},\nDelta millis ${delta}`);

    if(delta <= 0) {
        console.log("Game started")
        return ScoreBoardWithGuestBookImages();
    }
    else {
        const refreshIn = delta + 1000;
        console.log(`Refreshing in ${refreshIn} millis`)
        if(isFinite(refreshIn)) {
            setTimeout(function(){
                window.location.reload();
            }, refreshIn);
        }
        return PlacementMap();
    }

   
}

export default GuestBookMM;
