import logo from '../Assets/AElogo.png';
import '../App.css';
import { useContext, useEffect, useState } from 'react';
import { ApiContext } from './ApiContext';
import Choice from './Choice';
import { CSSTransition } from "react-transition-group"

function OrderSelection({ question, fadeIn }) {

    const { ApiCall } = useContext(ApiContext)
    const [choices, set_choices] = useState([])
    const [choice, set_choice] = useState([])

    useEffect(() => {
        if (question != null) {
            console.log("test")
            ApiCall("GET", "choice/" + question.id, null)
                .then(res => {
                    set_choices(res)
                    setTimeout(() => {
                        fadeIn(false)
                    }, 1500)
                })
        }
    }, [question])

    function postAnswer() {

        if (choice.length != choices.length || choice.length == 0) {
            return
        }

        let temp = ""
        for (var i = 0; i < choice.length; i++) {
            if (i != 0) {
                temp += ","
            }
            temp += choice[i].id
        }

        ApiCall("POST", "answer/" + question.id, { answer: temp })
            .then(res => {
                console.log(res)
                set_choice([])
                fadeIn(true)
            }).catch(err => {
                set_choice([])
                fadeIn(true)
            })
    }

    function updateChoice(c) {
        let temp = [...choice]
        let index = temp.findIndex(obj => obj.id == c.id)
        if (index == -1) {
            console.log("not found found")
            temp.push(c)
        }
        set_choice(temp)
    }

    function hasItem(c) {
        let index = choice.findIndex(obj => obj.id == c.id)
        if (index == - 1) {
            return false
        }
        return true
    }

    function getOrder(c) {
        let index = choice.findIndex(obj => obj.id == c.id)
        if (index == - 1) {
            return null
        }
        return index + 1
    }

    function reset() {
        set_choice([])
    }

    return (
        <>
            <h5 style={{ alignSelf: "flex-start", marginBottom: "10px" }}>Valitse järjestys</h5>
            <div className='question-container'>
                {choices.map((c, i) => {
                    return <>
                    <Choice order={getOrder(c)} set_choice={updateChoice} isSelected={hasItem(c)} choice={c} index={i} />
                    {i != choices.length - 1 && <div className='split-line'></div>}
                    </>
                })}
            </div>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                <div onClick={() => reset()} className='cancel-button' style={{ width: "45%" }}>Nollaa</div>
                <CSSTransition timeout={200} in={choice.length == choices.length} classNames={"partial-fade-transition"}  >
                    <div onClick={() => postAnswer()} className='login-button' style={{ width: "45%" }}>Tallenna</div>
                </CSSTransition>
            </div>
        </>
    );
}

export default OrderSelection;
