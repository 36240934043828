import "./App.css";
import { ApiProvider } from "./Components/ApiContext";
import Container from "./Components/Container";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import QrLogin from "./Components/QrLogin";
import ImageList from "./Components/ImageList";
import GuestBookMM from "./Components/GuestBookMM";
import PrivateScoreBoard from "./Components/PrivateScoreBoard";
import GuestBookWinners from "./Components/GuestBookWinners";
import Answers from "./Components/Answers";
import Halloween from "./Components/Halloween";
function App() {
  return (
    <ApiProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/login/:code" element={<QrLogin />} />
          <Route path="/guestbook" element={<ImageList />} />
          <Route path="/guestbookwinners" element={<GuestBookWinners />} />
          <Route path="/magicmirror" element={<GuestBookMM />} />
          <Route path="/pistetilanne" element={<PrivateScoreBoard />} />
          <Route path="/answers" element={<Answers/>}/>
          <Route path="halloweenmagicmirror" element={<Halloween/>} />
          <Route path="*" element={<Container />} />
        </Routes>
      </BrowserRouter>
    </ApiProvider>
  );
}

export default App;
