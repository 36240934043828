import "../App.css";
import { useContext, useEffect } from "react";
import { ApiContext } from "./ApiContext";
import { useNavigate, useParams } from "react-router-dom";

function QrLogin() {
  const { code } = useParams();
  const navigate = useNavigate();
  const { ApiCall } = useContext(ApiContext);

  useEffect(() => {
    console.log(`Logging in as ${code}`);
    ApiCall("POST", "user/login", { user_id: code.toUpperCase() })
      .then((res) => {
        document.cookie = "token-ea=" + res.token + ";path=/";
        navigate("/");
      })
      .catch((err) => {
        navigate("/");
      });
  }, []);

  return (
    <div className="App" style={{justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "column"}}>
      <div className="loader"></div>
      <div className="header-1" style={{marginTop: 20}}>Kirjaudutaan sisään</div>
    </div>
  );
}

export default QrLogin;
