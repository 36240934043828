import logo from '../Assets/AElogo.png';
import '../App.css';
import { useContext, useEffect, useState } from 'react';
import { ApiContext } from './ApiContext';
import { CSSTransition } from "react-transition-group"

function Choice({ choice, index, isSelected, set_choice, order, size, disabled, highlight }) {

    const [animating, set_animating] = useState(false)

    useEffect(() => {
        console.log(animating)
    }, [animating])
    return (
        <>
            <div onClick={() => !disabled && set_choice(choice)} className={'choice-button ' + choice.id} style={{ height: (choice.image != null || size != null) && "100px", width: size != null && size, opacity: disabled ? 0.7 : 1, border: highlight && "1px solid white", marginBottom: size != null && 5 }}>
                {order != null && <div style={{ position: "absolute", paddingLeft: "10px", alignItems: "center", display: "flex" }}>
                    <div className='header-1' style={{ backgroundColor: "black", height: "40px", width: "40px", borderRadius: "3px", alignItems: "center", justifyContent: "center", display: "flex" }}>
                        <>{order}</>
                    </div>
                </div>}
                {size == null && <CSSTransition onExited={() => set_animating(false)} onEnter={() => set_animating(true)} in={isSelected} classNames={choice.image == null ? "choice-number-animation" : "choice-image-animation"} timeout={200}>
                    {choice.image != null ? <div className='choice-image'><img src={choice.image} style={{ width: "100%", height: "100%", objectFit: "cover" }}></img></div>
                        : <div className='choice-number' style={{textOverflow: "ellipsis", justifyContent: isSelected && "flex-start", paddingLeft: isSelected && "20px"}}>
                            <div className="header-3" style={{textOverflow: "ellipsis", marginLeft: order != null && "50px"}}>{!isSelected ? (index + 1) : choice.choice.substring(0, 35) + (choice.choice.length > 36 ? "..." : "")}</div>
                        </div>
                    }
                </CSSTransition>
                }
                {(!isSelected && !animating && size == null) &&
                    <div style={{ display: "flex", height: "100%", flex: 1, alignItems: "center", textAlign: "left", padding: 10 }}>

                       <div className='body-3' style={{ whiteSpace: "normal" }}>{choice.choice}</div>
                    </div>
                }
                {size != null &&
                    <div style={{ display: "flex", height: "100%", flex: 1, alignItems: "center", justifyContent: "center", padding: choice.image == null && 10, overflow: "hidden" }}>
                        {choice.image != null ? <div className='connect-choice-image'><img src={choice.image} style={{ width: "100%", height: "100%", objectFit: "cover" }}></img></div>
                       : <div className='body-3' style={{ whiteSpace: "normal" }}>{choice.choice}</div>}
                    </div>
                }
            </div>
        </>
    );
}

export default Choice;
