import logo from '../Assets/AElogo.png';
import '../App.css';
import { useContext, useEffect, useState } from 'react';
import { ApiContext } from './ApiContext';
import { CSSTransition } from 'react-transition-group';
import { GuestBookDisplay } from './ImageList';
import LineTo from 'react-lineto';

function AnswerChoice({ choice, userSelected, correct, position, userPosition }) {

    return <div style={{ width: "100%", boxSizing: "border-box", backgroundColor: "black", marginTop: 10, display: "flex", flexDirection: "row", borderRadius: 4 }}>
        <div style={{ height: 50, width: 50, display: "flex", alignItems: "center", justifyContent: "center" }}>
            {(correct || position != null) && <div style={{ height: 30, width: 30, backgroundColor: "#DDAD66", borderRadius: "100px", display: "flex", justifyContent: "center", alignItems: "center" }} >
                {position != null && <div className='header-3' style={{ color: "black" }}>{position}</div>}
            </div>}
        </div>
        <div style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "center", textOverflow: "ellipsis", whiteSpace: "normal", fontSize: 12 }}>
            {choice.choice}
        </div>
        <div style={{ height: 50, width: 50, display: "flex", alignItems: "center", justifyContent: "center" }}>
            {(userSelected || userPosition != null) && <div style={{ height: 30, width: 30, border: "2px solid #DDAD66", borderRadius: "100px", boxSizing: "border-box", display: "flex", justifyContent: "center", alignItems: "center" }} >
                {userPosition != null && <div className='header-3'>{userPosition}</div>}
            </div>}
        </div>
    </div>

}


function ConnectChoice({ choice }) {

    return <div className={choice.id} key={choice.id} style={{ width: "40%", boxSizing: "border-box", backgroundColor: "black", marginTop: 10, display: "flex", flexDirection: "row", borderRadius: 4 }}>
        <div style={{ height: 100, width: "100%", display: "flex", alignItems: "center", justifyContent: "center", padding: choice.image == null && 10 }}>

            {choice.image != null ? <img style={{ height: "100%", width: "100%", objectFit: "cover" }} src={choice.image}></img> : <div className='header-3' style={{ whiteSpace: "normal", fontSize: 12 }}>{choice.choice}</div>}
        </div>
    </div>

}

function CorrectAnswerBoxSingleChoice({ entry }) {

    return <div className='guest-book-image-container'>
        <div className='header-1' style={{ marginBottom: 20, whiteSpace: "normal" }}>{entry.question}</div>
        <div className='header-2'>{"Maksimipisteet " + entry.question_max_points}</div>
        <div className='header-2'>{"Käytettyjen vihjeiden jälkeen " + entry.max_points_after_clues}</div>
        <div className='header-2'>{"Sait " + entry.points}</div>
        <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", marginTop: 40 }}>
            <div className='header-3'>Oikea</div>
            <div className='header-3'>Vastauksesi</div>
        </div>
        <div style={{ width: "100%", boxSizing: "border-box", display: "flex", flexDirection: "column" }}>
            {entry.choices.map(choice => {
                return <AnswerChoice correct={entry.answer == choice.id} userSelected={entry.user_answer == choice.id} choice={choice} />
            })}
        </div>
    </div>
}

function CorrectAnswerBoxOrderChoice({ entry }) {

    const [correct_answers] = useState(entry.answer.split(","))
    const [user_answers] = useState(entry.user_answer.split(","))

    return <div className='guest-book-image-container'>
        <div className='header-1' style={{ marginBottom: 20, whiteSpace: "normal" }}>{entry.question}</div>
        <div className='header-2'>{"Maksimipisteet " + entry.question_max_points}</div>
        <div className='header-2'>{"Käytettyjen vihjeiden jälkeen " + entry.max_points_after_clues}</div>
        <div className='header-2'>{"Sait " + entry.points}</div>
        <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", marginTop: 40 }}>
            <div className='header-3'>Oikea</div>
            <div className='header-3'>Vastauksesi</div>
        </div>
        <div style={{ width: "100%", boxSizing: "border-box", display: "flex", flexDirection: "column" }}>
            {entry.choices.map(choice => {
                return <AnswerChoice position={correct_answers.indexOf(choice.id.toString()) + 1} userPosition={user_answers.indexOf(choice.id.toString()) + 1} choice={choice} />
            })}
        </div>
    </div>
}

function CorrectAnswerBoxConnectChoice({ entry }) {

    const [correct_answers, set_correct_answers] = useState([])
    const [user_answers, set_user_answers] = useState([])

    useEffect(() => {
        var temp = []
        var jee = entry.answer.split(",")
        for (var i = 0; i < jee.length; i++) {
            var kek = jee[i].split(":")
            var pair = [entry.choices.find(({ id }) => id == kek[0]),
            entry.choices.find(({ id }) => id == kek[1])]

            temp.push(pair)
        }
        set_correct_answers(temp)
    }, [])

    useEffect(() => {

        if (entry.user_answer != null && entry.user_answer != "") {
            var temp = []
            var jee = entry.user_answer.split(",")
            for (var i = 0; i < jee.length; i++) {
                var kek = jee[i].split(":")
                var pair = [entry.choices.find(({ id }) => id == kek[0]),
                entry.choices.find(({ id }) => id == kek[1])]

                temp.push(pair)
            }
            set_user_answers(temp)
        }
    }, [])

    function onLeft(c) {
        let index = entry.choices.findIndex(obj => obj.id == c.id)
        if (index % 2 == 0) {
            return true
        }
        return false
    }

    return <div className='guest-book-image-container'>
        <div className='header-1' style={{ marginBottom: 20, whiteSpace: "normal" }}>{entry.question}</div>
        <div className='header-2'>{"Maksimipisteet " + entry.question_max_points}</div>
        <div className='header-2'>{"Käytettyjen vihjeiden jälkeen " + entry.max_points_after_clues}</div>
        <div className='header-2'>{"Sait " + entry.points}</div>
        <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", marginTop: 40 }}>
            <div>
                <div style={{width: "100%", height: 3, backgroundColor: "rgb(51, 204, 255)"}}></div>
                <div className='header-3'>Oikea</div>
            </div>
            <div>
                <div style={{width: "100%", height: 3, backgroundColor: "#DDAD66"}}></div>
                <div className='header-3'>Vastauksesi</div>
            </div>
        </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between", position: "relative", alignItems: "center" }}>
            {entry.choices.map(pair => {
                return <ConnectChoice choice={pair} />
            })}
            {correct_answers.map(pair => {
                return <div style={{ position: "absolute" }}><LineTo delay={1000} borderWidth={3} borderColor={"rgb(51, 204, 255)"} fromAnchor='center right' toAnchor='center left' from={onLeft(pair[0]) ? pair[0].id : pair[1].id} to={onLeft(pair[1]) ? pair[0].id : pair[1].id} /></div>
            })}

            {user_answers.map(pair => {
                return <div style={{ position: "absolute" }}><LineTo delay={1000} borderWidth={3} borderColor={"#DDAD66"} fromAnchor='center right' toAnchor='center left' from={onLeft(pair[0]) ? pair[0].id : pair[1].id} to={onLeft(pair[1]) ? pair[0].id : pair[1].id} /></div>
            })}
        </div>
    </div>
}



function CorrectAnswerBoxMultiChoice({ entry }) {

    const [correct_answers] = useState(entry.answer.split(","))
    const [user_answers] = useState(entry.user_answer.split(","))

    useEffect(() => {
        console.log(user_answers)
    }, [user_answers])

    return <div className='guest-book-image-container'>
        <div className='header-1' style={{ marginBottom: 20, whiteSpace: "normal" }}>{entry.question}</div>
        <div className='header-2'>{"Maksimipisteet " + entry.question_max_points}</div>
        <div className='header-2'>{"Käytettyjen vihjeiden jälkeen " + entry.max_points_after_clues}</div>
        <div className='header-2'>{"Sait " + entry.points}</div>
        <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", marginTop: 40 }}>
            <div className='header-3'>Oikea</div>
            <div className='header-3'>Vastauksesi</div>
        </div>
        <div style={{ width: "100%", boxSizing: "border-box", display: "flex", flexDirection: "column" }}>
            {entry.choices.map(choice => {
                return <AnswerChoice correct={correct_answers.includes(choice.id.toString())} userSelected={user_answers.includes(choice.id.toString())} choice={choice} />
            })}
        </div>
    </div>
}

function Answers({ }) {

    const { ApiCall } = useContext(ApiContext)
    const [loading, set_loading] = useState(false)
    const [questions, set_questions] = useState([])

    useEffect(() => {
        set_loading(true)
        setTimeout(() => {
            ApiCall("GET", "question/get_all_questions_with_answers", null)
                .then(res => {
                    console.log(res)
                    set_questions(res)
                    set_loading(false)
                }).catch(err => {
                    set_loading(false)
                })
        }, 1500)
    }, [])

    return (<>
        <div className='App'>
            {!loading && <>
                <div className='header'>
                    <div style={{ flex: 1, display: "flex" }}>
                        <div className="seal" style={{ position: "relative", height: "50px", width: "50px", margin: 0, left: 0, top: 0, right: 0, bottom: 0 }}>
                            <span className="embossed">
                                <img className='stamp' src={logo}></img>
                            </span>
                        </div>
                    </div>
                    <div className='header-1' style={{ fontSize: 30, alignSelf: "flex-start", marginTop: 30, marginBottom: 30, marginLeft: 30 }}>Vastaukset</div>
                </div>
                <div className='container'>
                    {questions.map((image, i) => {
                        return image.type == 1 ? <CorrectAnswerBoxSingleChoice entry={image} /> : image.type == 2 ? <CorrectAnswerBoxMultiChoice entry={image} /> : image.type == 3 ? <CorrectAnswerBoxOrderChoice entry={image} /> : <CorrectAnswerBoxConnectChoice entry={image} />
                    })}
                </div>
            </>}
        </div>
        <CSSTransition in={loading} timeout={1000} classNames="slide-from-bottom" unmountOnExit>
            <div className="door" style={{ top: "50%" }}>
            </div>
        </CSSTransition>
        <CSSTransition in={loading} timeout={1000} classNames="slide-from-top" unmountOnExit>
            <div className="door" style={{ borderBottom: "3px solid rgba(0,0,0)" }}>
            </div>
        </CSSTransition>

        <CSSTransition in={loading} timeout={2000} classNames="badge-animation" unmountOnExit>

            <div className="seal">
                <span className="embossed">
                    <img className='stamp' src={logo}></img>
                </span>
            </div>
        </CSSTransition>
        <CSSTransition in={loading} timeout={2000} classNames="drop-1" unmountOnExit>
            <div className='drop-1'></div>
        </CSSTransition>
        <CSSTransition in={loading} timeout={2000} classNames="drop-2" unmountOnExit>
            <div className='drop-2'></div>
        </CSSTransition>
    </>
    );
}

export default Answers;
