import logo from '../Assets/AElogo.png';
import E from "../Assets/E.png"
import A from "../Assets/A.png"
import '../App.css';
import { useContext, useEffect, useState } from 'react';
import { ApiContext } from './ApiContext';
import Login from './Login';
import Header from './Header';
import { Transition, CSSTransition } from "react-transition-group"
import QuestionContainer from './QuestionContainer';
import Terms from './Terms';
import ThankYouPage from './ThankYouPage';

function Container() {

    const { ApiCall } = useContext(ApiContext)
    const [needs_user_id, set_needs_user_id] = useState(false)
    const [user, set_user] = useState(null)
    const [terms_accepted, set_terms_accepted] = useState(false)

    const [fadeIn, setFadeIn] = useState(false)
    const [wave, set_wave] = useState(false)

    const [showThankYouPage, setShowThankYouPage] = useState(false)

    const [error, set_error] = useState("")

    useEffect(() => {
        console.log(wave)
    }, [wave])

    useEffect(() => {
        if (fadeIn) {
            setTimeout(function () {
                set_needs_user_id(false)
                set_user(null)
                ApiCall("GET", "user/get_user", null)
                    .then(res => {
                        console.log(res)
                        set_user(res)
                        if (res.terms_accepted) {
                            console.log("Terms accepted")
                            set_terms_accepted(true)
                        }
                        else {
                            console.log("User has not accepted terms")
                        }
                    }).catch(err => {
                        console.log(err)
                        set_needs_user_id(true)
                        setTimeout(() => {
                            open(false)
                        }, 1500)
                    })
            }, 1500)
        }
    }, [fadeIn])

    function open(out) {
        if (out) {
            setFadeIn(true)
        }
        else {
            let now = new Date()
            let start = new Date(process.env.REACT_APP_START_TIME * 1000)
            let total_end = new Date(process.env.REACT_APP_SHOW_RESULTS_TIME * 1000)
            if (now.getTime() < start.getTime()) {
                console.log("Not opening lul :D")
                let err = "Odota vielä " + getTimeDifference(now, start) +  " hätähousu!"
                set_error("")
                set_error(err)
            }
            else if (total_end.getTime() < now.getTime()) {
                set_error("")
                setShowThankYouPage(true)
                setFadeIn(false)
            }
            else {
                set_error("")
                console.log("opening")
                setFadeIn(false)
            }
        }
    }

    function getTimeDifference(start, end) {
        var startTime = new Date(start);
        var endTime = new Date(end);

        // Calculate the time difference in milliseconds
        var timeDiff = endTime - startTime;

        // Calculate the number of days, hours, minutes, and seconds
        var days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
        var hours = Math.floor((timeDiff / (1000 * 60 * 60)) % 24);
        var minutes = Math.floor((timeDiff / (1000 * 60)) % 60);
        var seconds = Math.floor((timeDiff / 1000) % 60);

        // Determine the largest unit of time
        if (days > 0) {
            return days + (days === 1 ? " päivä" : " päivää");
        } else if (hours > 0) {
            return hours + " h";
        } else if (minutes > 0) {
            return minutes + " min";
        } else {
            return seconds + " s";
        }
    }

    useEffect(() => {
        if (error.includes(" päivä")) {
            setTimeout(() => {
                open(false)
            }, 86400000)
        }
        if (error.includes(" h ")) {
            setTimeout(() => {
                open(false)
            }, 3600000)
        }
        else if (error.includes(" min ")) {
            setTimeout(() => {
                open(false)
            }, 60000)
        }
        else if (error.includes(" s ")) {
            setTimeout(() => {
                open(false)
            }, 1000)
        }
    }, [error])

    useEffect(() => {
        setFadeIn(true)
    }, [])

    return (
        <>
            <div className="App">
                {user != null && terms_accepted && !showThankYouPage && <Header fadeIn={open} user={user} />}
                {user != null && terms_accepted && !showThankYouPage && <QuestionContainer user={user} fadeIn={open} />}
                {user != null && terms_accepted && showThankYouPage && <ThankYouPage user={user} fadeIn={open} />}
                {needs_user_id && <Login fadeIn={open} />}
                {!terms_accepted && user != null && <Terms user={user} fadeIn={open} />}
            </div>
            <CSSTransition in={fadeIn} timeout={1000} classNames="slide-from-bottom" unmountOnExit>
                <div className="door" style={{ top: "50%" }}>
                </div>
                {/* <div className="Logo-overlay">
                    <CSSTransition in={fadeIn} timeout={600} classNames="letter-left">
                        <img src={A} className="App-logo" alt="logo" />
                    </CSSTransition>
                    <CSSTransition in={fadeIn} timeout={600} classNames="letter-right">
                        <img src={E} style={{ marginTop: "60px", marginLeft: "-20px" }} className="App-logo" alt="logo" />
                    </CSSTransition>
                </div>
    */}
            </CSSTransition>
            <CSSTransition in={fadeIn} timeout={1000} classNames="slide-from-top" unmountOnExit>
                <div className="door" style={{ borderBottom: "3px solid rgba(0,0,0)" }}>
                    <div className='header-1'>{error}</div>
                </div>
                {/* <div className="Logo-overlay">
                    <CSSTransition in={fadeIn} timeout={600} classNames="letter-left">
                        <img src={A} className="App-logo" alt="logo" />
                    </CSSTransition>
                    <CSSTransition in={fadeIn} timeout={600} classNames="letter-right">
                        <img src={E} style={{ marginTop: "60px", marginLeft: "-20px" }} className="App-logo" alt="logo" />
                    </CSSTransition>
                </div>
    */}
            </CSSTransition>

            <CSSTransition onEntered={() => set_wave(true)} in={fadeIn} timeout={2000} classNames="badge-animation" unmountOnExit>

                <div className="seal">
                    <span className="embossed">
                        <img className='stamp' src={logo}></img>
                    </span>
                </div>
                {/* <div className="Logo-overlay">
                    <CSSTransition in={fadeIn} timeout={600} classNames="letter-left">
                        <img src={A} className="App-logo" alt="logo" />
                    </CSSTransition>
                    <CSSTransition in={fadeIn} timeout={600} classNames="letter-right">
                        <img src={E} style={{ marginTop: "60px", marginLeft: "-20px" }} className="App-logo" alt="logo" />
                    </CSSTransition>
                </div>
    */}
            </CSSTransition>
            <CSSTransition in={fadeIn} timeout={2000} classNames="drop-1" unmountOnExit>
                <div className='drop-1'></div>
            </CSSTransition>
            <CSSTransition in={fadeIn} timeout={2000} classNames="drop-2" unmountOnExit>
                <div className='drop-2'></div>
            </CSSTransition>
            {/*<CSSTransition in={wave} timeout={999999999} classNames="wave-animation" unmountOnExit>
                
                    <div className='seal-wave'></div>
                {/* <div className="Logo-overlay">
                    <CSSTransition in={fadeIn} timeout={600} classNames="letter-left">
                        <img src={A} className="App-logo" alt="logo" />
                    </CSSTransition>
                    <CSSTransition in={fadeIn} timeout={600} classNames="letter-right">
                        <img src={E} style={{ marginTop: "60px", marginLeft: "-20px" }} className="App-logo" alt="logo" />
                    </CSSTransition>
                </div>
            </CSSTransition>
            */}
        </>
    );
}

export default Container;
